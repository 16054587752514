import {
  CurrencyValue,
  getRequestUrl,
  ResourceExportResult,
  ResourceListResult,
  ResourceModifier,
  ResourceResult,
  useFetcher,
  useServiceResource,
  useServiceResourceExport,
  useServiceResourceList,
  useServiceResourceModifier,
} from "./api";
import { SalesforceOpportunity } from "../salesforce/opportunities";
import { Dictionary } from "./dictionary";

export type DealType =
  | "new_business"
  | "upsell"
  | "upsell_new"
  | "growth"
  | "renewal"
  | "renewal_extension"
  | "restructure";
export type ContractDocumentType = "order_form" | "custom_tc" | "email_confirmation";
export type ContractDocument = { date: string; type: ContractDocumentType; url: string };
export interface Contract {
  id: number | null;
  accountId: string;
  opportunityId: string;

  closeDate: string;
  startDate: string | null;
  endDate: string | null;
  parentStartDate: string | null;
  parentEndDate: string | null;

  partnerEntity: string | null;
  parentContract: number | null;
  accountNumber: string;
  name: string;
  contractingEntity: string;
  endUser: string | null;
  dealTypes: DealType[];
  autoPriceIncrease: string | null;
  renewalTermLength: string | null;
  priceIncreaseAllowance: string | null;

  autoRenew: boolean | null;
  noticePeriod: number | null;
  deliveryCountry: string;
  deliveryState: string | null;

  tcv: string | null;
  acv: string | null;
  mcv: string | null;
  dcv: string | null;

  documents: ContractDocument[];
  comment: string;

  netsuiteExcluded?: boolean;
}

export interface ContractExport extends Contract {
  arr: CurrencyValue;
  mrr: CurrencyValue;
}

export interface ContractValues {
  id?: number;
  // this will need to be changed with the migrations
  contract?: number | null;
  // this could probably be currency
  currency?: string;
  tcv?: number;
  acv?: number;
  mcv?: number;
  dcv?: number;
  comment?: string;
}

export interface DynamicValues {
  id?: number;
  contract?: number | null;
  currency?: string;
  startDate?: string;
  endDate?: string;
  arr?: number;
  mrr?: number;
  drr?: number;
  comment?: string;
}

export interface NetsuiteSyncLog {
  id: number;
  createTs: string;
  salesOrderId: string | null;
  salesOrderCreated: boolean | null;
  usedBillingScheduleId: string | null;
  success: boolean;
  errorMessage: string | null;
}

export const DEAL_TYPE_MAPPING: { [key: string]: DealType } = {
  "Existing Business": "renewal",
  "New Business": "new_business",
  "New Business (Growth)": "growth",
  "New Business (Upsell)": "upsell_new",
  Pilot: "new_business",
};
export const CONTRACTING_ENTITY_MAPPING: { [key: string]: string } = {
  "Scandit Inc.": "Scandit Inc., US",
  "Scandit AG": "Scandit AG, CH",
};

export function contractFromSalesforce(
  opportunity: SalesforceOpportunity,
  accountId: string,
  parentContractId: number | null,
  dictionary: Dictionary,
  dealType?: DealType
): Contract {
  let country = "";
  if (opportunity.billingAddress) {
    const billingAddressInfo = opportunity.billingAddress.split(",");
    country = billingAddressInfo[billingAddressInfo.length - 1].trim();
    if (!dictionary.COUNTRIES.includes(country)) country = "";
  }
  dealType = dealType || DEAL_TYPE_MAPPING[opportunity.type];
  const documents: ContractDocument[] = [];
  if (opportunity.linkToOrderForm) {
    documents.push({
      date: opportunity.closeDate,
      type: "order_form",
      url: opportunity.linkToOrderForm,
    });
  }
  // (BA-15) Could also be O.F. amendment here
  if (opportunity.linkToConfirmationOfTierGrowth) {
    documents.push({
      date: opportunity.closeDate,
      type: "email_confirmation",
      url: opportunity.linkToConfirmationOfTierGrowth,
    });
  }
  if (opportunity.linkToCustomerSpecificTC) {
    documents.push({
      date: opportunity.closeDate,
      type: "custom_tc",
      url: opportunity.linkToCustomerSpecificTC,
    });
  }
  return {
    id: null,
    closeDate: opportunity.closeDate,
    startDate: null,
    endDate: null,
    parentStartDate: null,
    parentEndDate: null,
    accountId: accountId,
    opportunityId: opportunity.id,
    partnerEntity: null,
    parentContract: parentContractId,
    accountNumber: "",
    name: opportunity.name,
    contractingEntity: CONTRACTING_ENTITY_MAPPING[opportunity.contractingEntity] || "",
    endUser: null, // later from Claude
    dealTypes: dealType ? [dealType] : [],
    autoPriceIncrease: null,
    renewalTermLength: null,
    priceIncreaseAllowance: null,
    autoRenew: opportunity.autoRenewal === "Yes" ? true : opportunity.autoRenewal === "No" ? false : null,
    noticePeriod: opportunity.syncedQuote.noticePeriod === "None" ? null : opportunity.syncedQuote.noticePeriod ?? null,
    deliveryCountry: country,
    deliveryState: null,
    documents: documents,
    comment: "",
    tcv: null,
    acv: null,
    mcv: null,
    dcv: null,
  };
}

export function useContracts(
  accountId: string,
  page?: number,
  params?: { [key: string]: string }
): ResourceListResult<Contract> {
  return useServiceResourceList<Contract>(`/v1/accounts/${accountId}/contracts/`, page, params);
}

export function useContractsExport(): ResourceExportResult<ContractExport> {
  return useServiceResourceExport<ContractExport>("contract");
}

export function useContract(accountId: string, contractId: number): ResourceResult<Contract> {
  return useServiceResource<Contract>(`/v1/accounts/${accountId}/contracts/${contractId}/`);
}

export function useContractModifier(accountId: string): ResourceModifier<Contract> {
  return useServiceResourceModifier<Contract>(`/v1/accounts/${accountId}/contracts/`);
}

export function useContractValues(
  accountId: string,
  contractId: string,
  params?: { [key: string]: string }
): ResourceResult<ContractValues> {
  params ||= {};
  return useServiceResource<ContractValues>(`/v1/accounts/${accountId}/contracts/${contractId}/values/`, 0, params);
}

export function useContractValuesModifier(accountId: string, contractId: string): ResourceModifier<ContractValues> {
  return useServiceResourceModifier<ContractValues>(`/v1/accounts/${accountId}/contracts/${contractId}/values/`);
}

export function useDynamicValues(accountId: string, contractId: string): ResourceListResult<DynamicValues> {
  return useServiceResourceList<DynamicValues>(`/v1/accounts/${accountId}/contracts/${contractId}/dynamic-values/`);
}

export function useDynamicValuesModifier(accountId: string, contractId: string): ResourceModifier<DynamicValues> {
  return useServiceResourceModifier<DynamicValues>(`/v1/accounts/${accountId}/contracts/${contractId}/dynamic-values/`);
}

export function useNetsuite(
  accountId: string,
  contractId: number
): ResourceListResult<NetsuiteSyncLog> & {
  triggerSync: () => Promise<void>;
  exclude: (state: boolean) => Promise<void>;
  excluded: boolean;
} {
  const fetcher = useFetcher<void>();
  const baseUrl = `/v1/accounts/${accountId}/contracts/${contractId}/netsuite`;

  const result = useServiceResourceList<NetsuiteSyncLog>(`${baseUrl}/logs/`);
  return {
    ...result,
    excluded: result.rawData?.excluded,
    triggerSync: async () => {
      return fetcher(`${getRequestUrl(`${baseUrl}/sync/`)}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
    },
    exclude: async (state: boolean) => {
      return fetcher(`${getRequestUrl(`${baseUrl}/exclude/`, { state: state ? "1" : "0" })}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
    },
  };
}
