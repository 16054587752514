import useSWR from "swr";
import { config } from "../config";
import parseISO from "date-fns/parseISO";
import { Permission } from "./auth";
import uniq from "lodash/uniq";
import { useSelector } from "react-redux";
import { selectUserAccessToken } from "../store/authSlice";

export const USAGE_METRIC_FIELDS: { [key: string]: string[] } = {
  "yearly-ad-by-lk": ["maxDevicesPerYear", "maxScansPerDevicePerYear"],
  "monthly-ad-by-lk": ["maxDevicesPerMonth", "maxScansPerDevicePerYear"],
  "yearly-ad-by-sub": ["maxDevicesPerYear", "maxScansPerDevicePerYear"],
  "monthly-ad-by-sub": ["maxDevicesPerMonth", "maxScansPerDevicePerYear"],
  activations: ["maxActivations"],
  scans: ["maxScansPerYear", "maxScansPerDevicePerYear"],
  stores: ["maxStores", "avgMonthlyDevicesPerStore", "avgScansPerStore", "storeType", "storeEngagementTier"],
  custom: ["maxDevicesPerYear", "maxDevicesPerMonth", "maxScansPerYear", "maxActivations", "maxScansPerDevicePerYear"],
};
export const USAGE_METRIC_ALL_FIELDS: string[] = uniq(Object.values(USAGE_METRIC_FIELDS).flat());

export interface CurrencyValue {
  EUR: string;
  CHF: string;
  USD: string;
  GBP: string;
  JPY: string;
  PLN: string;
}

export class APIError extends Error {
  public is404: boolean = false;
  public status?: number;

  constructor(msg: string, status?: number) {
    super(msg);
    this.is404 = status == 404;
    this.status = status;
  }
}

export class ValidationError extends APIError {
  public errors: { [key: string]: string[] };
  constructor(errors: { [key: string]: string[] }) {
    super("Validation error");
    this.errors = errors;
  }
}

function fetcherFactory<T = any>(token: string): (input: RequestInfo, init?: RequestInit) => Promise<T> {
  return async (input: RequestInfo, init?: RequestInit): Promise<T> => {
    const requestInit = init || {};
    requestInit.headers ||= {};
    (requestInit.headers as any)["Authorization"] = `Bearer ${token}`;

    const res = await fetch(input, requestInit);
    switch (res.status) {
      case 200:
      case 201:
        return res.json();
      case 204:
        return undefined as any;
      case 400:
        const payload = await res.json();
        throw new ValidationError(payload);
      case 404:
        throw new APIError("Object not found", res.status);
      default:
        throw new APIError(`Unexpected error: ${res.status}`, res.status);
    }
  };
}

//TODO: swr config with error handling

export interface Result<T> {
  isLoading: boolean;
  isError: boolean;
  error?: APIError;
  mutate: (obj?: T) => Promise<T | undefined>;
}
export interface ResourceResult<T> extends Result<T> {
  data?: T;
}
export interface ResourceListResult<T> extends Result<T> {
  data?: T[];
  count?: number;
  page?: number;
  rawData?: any;
}

export function getRequestUrl(url: string, params?: { [key: string]: string }): string {
  const requestUrl = new URL(config.serviceUrl);
  let basePath = requestUrl.pathname;
  if (basePath.endsWith("/")) basePath = basePath.substring(0, basePath.length - 1);
  requestUrl.pathname = basePath + url;
  if (params) {
    Object.entries(params).forEach((entry) => {
      requestUrl.searchParams.append(entry[0], entry[1]);
    });
  }
  return requestUrl.toString();
}
export interface UserAuth {
  token: string;
  expires: Date;
  permissions: Array<Permission>;
}

export async function obtainTokenUsingSF(
  accessToken: string,
  userId: string,
  organizationId: string
): Promise<UserAuth | null> {
  const res = await fetch(getRequestUrl("/v1/auth/salesforce/"), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ accessToken, userId, organizationId, url: config.salesforceUrl }),
  });
  switch (res.status) {
    case 200:
      const payload = (await res.json()) as Omit<UserAuth, "expires"> & { expires: string };
      if (!payload.token || payload.token == "" || !payload.expires) return null;
      const expires = parseISO(payload.expires);
      return {
        ...payload,
        expires,
      };
    case 400:
      console.error(`Could not obtain a token: ${res}`);
      return null;
    default:
      throw new APIError(`Unexpected error: ${res.status}`, res.status);
  }
}

export async function obtainTokenUsingGoogle(authCode: string): Promise<UserAuth | null> {
  const res = await fetch(getRequestUrl("/v1/auth/google/"), {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ authCode }),
  });
  switch (res.status) {
    case 200:
      const payload = (await res.json()) as Omit<UserAuth, "expires"> & { expires: string };
      if (!payload.token || payload.token == "" || !payload.expires) return null;
      const expires = parseISO(payload.expires);
      return {
        ...payload,
        expires,
      };
    case 400:
      console.error(`Could not obtain a token: ${res}`);
      return null;
    default:
      throw new APIError(`Unexpected error: ${res.status}`, res.status);
  }
}

export interface ResourceModifier<T> {
  create: (object: T) => Promise<T>;
  update: (object: T) => Promise<T>;
  delete: (object: T) => Promise<void>;
}

export function useFetcher<T>() {
  const bearerToken = useSelector(selectUserAccessToken);
  return fetcherFactory<T>(bearerToken);
}

export function useServiceResourceModifier<T>(url: string): ResourceModifier<T> {
  const fetcher = useFetcher<void>();
  return {
    create: (object: T) =>
      fetcher(getRequestUrl(url), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      }),
    update: (object: T) =>
      fetcher(`${getRequestUrl(url)}${(object as any).id}/`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(object),
      }),
    delete: (object: T) =>
      fetcher(`${getRequestUrl(url)}${(object as any).id}/`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      }),
  } as any;
}
export function useServiceResource<T>(
  url: string,
  page: number = 0,
  params?: { [key: string]: string }
): ResourceResult<T> {
  const bearerToken = useSelector(selectUserAccessToken);
  const { data, error, mutate } = useSWR<T>(getRequestUrl(url, params) as any, fetcherFactory<T>(bearerToken) as any);
  return {
    data: data,
    isLoading: !error && !data,
    isError: !!error,
    error: error,
    mutate: mutate,
  };
}

interface PaginatedResponse<T> {
  results: T[];
  count: number;
  previous: string;
  next: string;
}

export function useServiceResourceList<T>(
  url: string,
  page?: number,
  params?: { [key: string]: string }
): ResourceListResult<T> {
  const requestPage = page || 1;
  const requestUrl = getRequestUrl(url, { page: String(requestPage), ...params });
  const bearerToken = useSelector(selectUserAccessToken);
  const { data, error, mutate } = useSWR<PaginatedResponse<T>>(
    requestUrl.toString() as any,
    fetcherFactory<T>(bearerToken) as any
  );
  return {
    data: data?.results,
    count: data?.count,
    page: requestPage,
    isLoading: !error && !data,
    isError: !!error,
    error: error,
    mutate: mutate as any,
    rawData: data,
  };
}

export type ResourceExportReceiver<T> = (objects: T[], final: boolean, page: number, pages: number) => Promise<void>;

export interface ResourceExportResult<T> {
  export: (date: string, state: string, receiver: ResourceExportReceiver<T>) => void;
}

export interface ResourceExportDirectResult<T> {
  exportDirect: (receiver: ResourceExportReceiver<T>) => void;
}

export interface ResourceExportPeriodResult<T> {
  exportPeriod: (startDate: string, endDate: string, objectType: string, receiver: ResourceExportReceiver<T>) => void;
}

export function useServiceResourceExport<T>(typeName: string): ResourceExportResult<T> {
  const fetcher = useFetcher<{ results: T[]; pages: number; page: number }>();
  const maxRetries = 10;
  const retryDelay = 3000;

  const fetchPage = async (
    page: number,
    params: { [key: string]: string },
    receiver: ResourceExportReceiver<T>,
    retry: number = 0
  ): Promise<number> => {
    params.page = String(page);
    let result: Awaited<ReturnType<typeof fetcher>> | undefined = undefined;
    try {
      result = await fetcher(getRequestUrl(`/v1/export/`), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      });
    } catch (e: any) {
      // retry on any error
      if (retry < maxRetries) {
        console.log(`Error: ${e}, retry: ${retry + 1}`);
        return new Promise((resolve) =>
          setTimeout(async () => {
            resolve(await fetchPage(page, params, receiver, retry + 1));
          }, retryDelay)
        );
      } else {
        throw e;
      }
    }
    const pages = result!.pages;
    await receiver(result!.results, page == pages, page, pages);
    return pages;
  };

  return {
    export: async (date, state, receiver) => {
      const params: { [key: string]: string } = {
        date,
        state,
        objectType: typeName,
      };
      let page = 1;
      let pages = 1;
      while (page <= pages) {
        pages = await fetchPage(page, params, receiver);
        page += 1;
      }
    },
  };
}

export function useServiceResourceExportPeriod<T>(typeName: string): ResourceExportPeriodResult<T> {
  const fetcher = useFetcher<{ results: T[]; pages: number; page: number }>();

  async function exportPeriod(
    startDate: string,
    endDate: string,
    objectType: string,
    receiver: ResourceExportReceiver<T>
  ) {
    let page = 1;
    let pages = 1;
    while (page <= pages) {
      const result = await fetcher(
        getRequestUrl(`/v1/export/${typeName}/`, {
          page: page.toString(),
          start_date: startDate,
          end_date: endDate,
          object_type: objectType,
        }),
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      pages = result.pages;
      await receiver(result.results, page == pages, page, pages);
      page += 1;
    }
  }

  return {
    exportPeriod: exportPeriod,
  };
}

export function useServiceResourceExportDirect<T>(typeName: string): ResourceExportDirectResult<T> {
  const fetcher = useFetcher<{ results: T[]; pages: number; page: number }>();
  return {
    exportDirect: async (receiver) => {
      let page = 1;
      let pages = 1;
      while (page <= pages) {
        const result = await fetcher(getRequestUrl(`/v1/export/${typeName}/`, { page: page.toString() }), {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        pages = result.pages;
        await receiver(result.results, page == pages, page, pages);
        page += 1;
      }
    },
  };
}

// action to perform upon one page of results
export type OnPageAction<TResults> = (results: TResults, page: number, pages: number) => Promise<void>;

// hook providing for-each-page loop upon a paginated resource
export function useForEachPage<TQuery, TResults>(
  resource: string
): (query: TQuery, action: OnPageAction<TResults>) => void {
  const fetcher = useFetcher<{ results: TResults; pages: number; page: number }>();

  async function forEach(query: TQuery, action: OnPageAction<TResults>) {
    let page = 1;
    let pages = 1;
    while (page <= pages) {
      const result = await fetcher(
        getRequestUrl(`/v1/${resource}/`, {
          page: page.toString(),
          ...query,
        }),
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      pages = result.pages;
      await action(result.results, page, pages);
      page += 1;
    }
  }

  return forEach;
}
