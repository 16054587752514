import * as React from "react";
import { NetsuiteContractStatus, useNetsuite } from "../../service/netsuite";
import { NetsuiteSyncLog } from "../../service/contracts";
import { usePermissions } from "../../service/auth";
import { Link, Redirect } from "react-router-dom";
import { useNotifications } from "../../notifications";
import MuiLink from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { Skeleton, Table, TableFooter, TableHead } from "@mui/material";
import { TableContainer } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Button from "@mui/material/Button";
import { groupBy } from "lodash";

const NETSUITE_BASE_URL = "https://5729187.app.netsuite.com";

export function Netsuite(): JSX.Element {
  const { hasPermission } = usePermissions();
  const { addNotification } = useNotifications();
  const [collectedData, setCollectedData] = React.useState<NetsuiteContractStatus[][]>([]);
  const [page, setPage] = React.useState<number>(1);

  const { data, isLoading } = useNetsuite(page);

  if (!hasPermission("netsuite")) {
    addNotification({
      level: "error",
      text: "You do not have the required permissions to access this page.",
    });
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    if (data) {
      const newData = [...collectedData];
      newData[page - 1] = data;
      setCollectedData(newData);
    }
  }, [data]);

  return (
    <div>
      <h1>Netsuite</h1>
      <h2>Contracts by creation time</h2>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Account ID</TableCell>
              <TableCell>Creation time</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Sales order</TableCell>
              <TableCell>Sync status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            )}
            <Rows data={collectedData} />
            {isLoading && (
              <TableRow>
                <TableCell>
                  <Skeleton key={"skeleton0"} variant="text" animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton key={"skeleton1"} variant="text" animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton key={"skeleton2"} variant="text" animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton key={"skeleton3"} variant="text" animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton key={"skeleton4"} variant="text" animation="wave" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6} align="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Load more
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

function Rows(props: { data: NetsuiteContractStatus[][] }): JSX.Element {
  const rows = groupBy(
    props.data.flatMap((data) => data),
    (item) => new Date(item.contractCreateTs).toLocaleDateString()
  );
  return (
    <>
      {Object.entries(rows).map(([date, data]) => (
        <React.Fragment key={date}>
          <TableRow>
            <TableCell variant="head" colSpan={6} align="center">
              {date}
            </TableCell>
          </TableRow>
          {data.map((item, idx) => (
            <TableRow key={idx}>
              <TableCell>
                <MuiLink target="_blank" component={Link} color="primary" to={`/accounts/${item.accountId}`}>
                  {item.accountId}
                </MuiLink>
              </TableCell>
              <TableCell>{new Date(item.contractCreateTs).toLocaleString()}</TableCell>
              <TableCell>
                <MuiLink
                  target="_blank"
                  component={Link}
                  color="primary"
                  to={`/accounts/${item.accountId}/contracts/${item.contractId}`}
                >
                  {item.contractName}
                </MuiLink>
              </TableCell>
              <TableCell>
                {getLastLog(item.logs) && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${NETSUITE_BASE_URL}/app/accounting/transactions/salesord.nl?id=${
                      getLastLog(item.logs)!.salesOrderId
                    }`}
                  >
                    <OpenInNewIcon fontSize="small" /> {getLastLog(item.logs)!.salesOrderId}
                  </a>
                )}
              </TableCell>
              <TableCell>
                <SyncStatus
                  logs={item.logs}
                  accountId={item.accountId}
                  contractId={item.contractId}
                  excluded={item.excluded}
                />
              </TableCell>
            </TableRow>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}

function getLastLog(logs: NetsuiteSyncLog[]): NetsuiteSyncLog | null {
  return logs.length > 0 ? logs[logs.length - 1] : null;
}

function SyncStatus(props: {
  logs: NetsuiteSyncLog[];
  accountId: string;
  contractId: number;
  excluded: boolean;
}): JSX.Element {
  const lastLog = getLastLog(props.logs);
  let status;
  if (props.excluded) {
    status = <span style={{ color: "gray" }}>Excluded</span>;
  } else if (lastLog == null) {
    status = <span style={{ color: "gray" }}>Not synced</span>;
  } else if (lastLog.success) {
    status = <span style={{ color: "green" }}>Success</span>;
  } else {
    status = <span style={{ color: "red" }}>Failed</span>;
  }
  return (
    <div>
      <strong>{status}</strong>
      <br />
      <MuiLink
        target="_blank"
        component={Link}
        color="primary"
        to={`/accounts/${props.accountId}/contracts/${props.contractId}/netsuite`}
      >
        See more
      </MuiLink>
    </div>
  );
}
