import { ResourceListResult, useServiceResourceList } from "./api";
import { NetsuiteSyncLog } from "./contracts";

export interface NetsuiteContractStatus {
  contractId: number;
  contractName: string;
  contractCreateTs: string;
  accountId: string;
  logs: NetsuiteSyncLog[];
  excluded: boolean;
}

export function useNetsuite(page: number): ResourceListResult<NetsuiteContractStatus> {
  return useServiceResourceList<NetsuiteContractStatus>("/v1/netsuite/", page);
}
