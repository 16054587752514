import * as React from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router";
import { ContentBox } from "../../../components/ContentBox";
import { useContract, useNetsuite as useContractNetsuite } from "../../../service/contracts";
import { Header } from "../../Header";
import { usePermissions } from "../../../service/auth";
import { Redirect } from "react-router-dom";
import { useNotifications } from "../../../notifications";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";

export function ContractNetsuite(): JSX.Element {
  const { hasPermission } = usePermissions();
  const { addNotification } = useNotifications();
  const { accountId, contractId } = useParams<{ accountId: string; contractId: string }>();
  const [syncing, setSyncing] = React.useState<boolean>(false);

  const { data: contract, isLoading: contractLoading } = useContract(accountId, parseInt(contractId));

  const { data, isLoading, triggerSync, exclude, excluded, mutate } = useContractNetsuite(
    accountId,
    parseInt(contractId)
  );

  if (!hasPermission("netsuite")) {
    addNotification({
      level: "error",
      text: "You do not have the required permissions to access this page.",
    });
    return <Redirect to="/" />;
  }

  const loading = contractLoading || isLoading || syncing;
  return (
    <>
      <Header accountId={accountId} contract={contract} page="Netsuite" loading={loading} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ContentBox header="Netsuite">
            {loading ? (
              <>
                <Skeleton variant="text" animation="wave" width={400} />
                <Skeleton variant="text" animation="wave" width={400} />
                <Skeleton variant="text" animation="wave" width={400} />
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={data?.some((l) => l.success) || excluded}
                  onClick={async () => {
                    setSyncing(true);
                    try {
                      await triggerSync();
                      await mutate();
                    } finally {
                      setSyncing(false);
                    }
                  }}
                >
                  Trigger sync
                </Button>
                &nbsp;
                <Button
                  variant="contained"
                  color={excluded ? "success" : "warning"}
                  size="small"
                  disabled={data?.some((l) => l.success)}
                  onClick={async () => {
                    setSyncing(true);
                    try {
                      await exclude(excluded ? false : true);
                      await mutate();
                    } finally {
                      setSyncing(false);
                    }
                  }}
                >
                  {excluded ? "Resume" : "Exclude"}
                </Button>
                {data?.map((log) => (
                  <p key={log.id}>
                    <i>{log.createTs}</i> -&nbsp;
                    {log.success ? <b style={{ color: "green" }}>SUCCESS</b> : <b style={{ color: "red" }}>FAILURE</b>}
                    &nbsp;
                    {log.salesOrderId && (
                      <>
                        <b>SO:</b>
                        {log.salesOrderId}
                      </>
                    )}
                    &nbsp;
                    {log.salesOrderCreated !== null && (
                      <>
                        <b>SO created:</b>
                        {log.salesOrderCreated ? "yes" : "no"}
                      </>
                    )}
                    &nbsp;
                    <>
                      <b>Billing schedule ID:</b>
                      {log.usedBillingScheduleId || "-"}
                    </>
                    &nbsp;
                    {log.errorMessage !== null && (
                      <>
                        <b>Error:</b>
                        {log.errorMessage}
                      </>
                    )}
                    &nbsp;
                  </p>
                ))}
              </>
            )}
          </ContentBox>
        </Grid>
      </Grid>
    </>
  );
}
